<template>
  <report-action-bar
    class="comparison-report-action-bar flex justify-end"
    show-copy
    show-export
    show-print
    show-download
    :disabled="disabled"
    :download-name="downloadName"
    :export-data="exportData"
    :export-name="exportName"
    :document-url="documentUrl"
    :report-input="reportInput"
    @copying="$emit('copying', $event)"
    @downloading="$emit('downloading', $event)"
    @exporting="$emit('exporting', $event)"
  />
</template>

<script>
import ComparisonReportMixin from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportMixin';
import ReportActionBar from '@/components/Supplier/Accounting/Reports/ReportActionBar';
import { cleanToolsState } from '@/utils/helpers/searchTools';
import qs from 'qs';

export default {
  components: { ReportActionBar },
  mixins: [ComparisonReportMixin],
  props: {
    url: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: 'Comparison_Report'
    },
    reportId: {
      type: String,
      required: true
    },
    disabled: Boolean
  },
  computed: {
    reportInput() {
      return {
        reportId: this.reportId,
        reportType: '',
        metrics: this.metrics,
        comparisons: this.comparisons,
        sort: this.sort,
        filter: this.filter,
        settings: this.settings,
        filterToolsState: cleanToolsState(this.filterToolsState)
      };
    },
    documentUrl() {
      let jsonStr = JSON.stringify(
        {
          dimensions: this.dimensions,
          metrics: this.metrics,
          comparisons: this.comparisons,
          sort: this.sort,
          filter: this.filter,
          settings: this.settings,
          filterToolsState: cleanToolsState(this.filterToolsState)
        },
        (key, value) => {
          if (key === 'tool' || key === 'searchValue') {
            return undefined;
          }

          return value;
        }
      );

      return this.url + '?' + qs.stringify({ report: btoa(jsonStr) });
    },
    exportName() {
      return this.secondaryDimensions.length > 0
        ? this.name + '.csv'
        : this.name + '_By_Dimensions.csv';
    },
    downloadName() {
      return this.secondaryDimensions.length > 0
        ? this.name + '.pdf'
        : this.name + '_By_Dimensions.pdf';
    }
  }
};
</script>
