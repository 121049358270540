<template>
  <div class="supplier-ar-aging-report flex">
    <supplier-report-sidebar
      v-model="reportInput.settings"
      class="flex-shrink-0"
    >
      <custom-el-form-item label="Report Type" label-class="font-bold">
        <el-select v-model="reportInput.type">
          <el-option :value="ReportType.SUMMARY" />
          <el-option :value="ReportType.DETAILS" />
        </el-select>
      </custom-el-form-item>
    </supplier-report-sidebar>

    <div class="flex-grow ml-5 report-document-box">
      <supplier-aging-report-search-tools-query
        @data="
          searchTools =
            $event.supplier.accountingReports.arAgingReport.searchTools
        "
      />

      <supplier-aging-report-query
        :filter="reportInput.filter"
        @data="
          reportData = $event.supplier.accountingReports.arAgingReport.data
        "
      >
        <template v-slot="{ isLoading }">
          <div
            class="transition-all transition-d-.5"
            :style="{
              maxWidth: reportInput.settings.isLandscape ? '11in' : '8.5in'
            }"
          >
            <ar-aging-action-bar
              :data="reportData"
              :type="reportInput.type"
              :filter="reportInput.filter"
              :settings="reportInput.settings"
              :filter-tools-state="reportInput.filterToolsState"
              class="ar-aging-action-bar my-5"
              @downloading="isDownloading = $event"
            />

            <transition name="fade" mode="out-in">
              <div
                v-if="reportInput.type === ReportType.SUMMARY"
                class="ar-aging-summary-box"
              >
                <ar-aging-summary-report
                  shadow
                  :short-currency="reportInput.settings.isShortCurrency"
                  :landscape.sync="reportInput.settings.isLandscape"
                  :loading-body="isLoading"
                  :loading="!searchTools"
                  :exporting="isDownloading"
                  :data="reportData"
                  :search-tools="searchTools"
                  :settings="reportInput.settings"
                  :filter-tools-state.sync="reportInput.filterToolsState"
                  @filter="reportInput.filter = $event"
                  @settings="reportInput.settings = $event"
                />
              </div>
              <div v-else class="ar-aging-details-box">
                <ar-aging-details-report
                  shadow
                  :loading-body="isLoading"
                  :loading="!searchTools"
                  :exporting="isDownloading"
                  :data="reportData"
                  :search-tools="searchTools"
                  :settings="reportInput.settings"
                  :filter-tools-state.sync="reportInput.filterToolsState"
                  @filter="reportInput.filter = $event"
                  @settings="reportInput.settings = $event"
                />
              </div>
            </transition>
          </div>
        </template>
      </supplier-aging-report-query>
    </div>
  </div>
</template>

<script>
import ArAgingActionBar from '@/components/Supplier/Accounting/Reports/ArAgingReport/ArAgingActionBar';
import ArAgingDetailsReport from '@/components/Supplier/Accounting/Reports/ArAgingReport/ArAgingDetailsReport';
import ArAgingSummaryReport from '@/components/Supplier/Accounting/Reports/ArAgingReport/ArAgingSummaryReport';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import SupplierAgingReportQuery from '@/components/Queries/Accounting/Reports/SupplierAgingReportQuery';
import SupplierAgingReportSearchToolsQuery from '@/components/Queries/Accounting/Reports/SupplierAgingReportSearchToolsQuery';
import SupplierReportSidebar from '@/components/Supplier/Accounting/Reports/SupplierReportSidebar';
import { ReportType } from '@/constants';
import { UrlParamFormat, urlParams } from '@/utils/helpers';

export default {
  components: {
    SupplierReportSidebar,
    ArAgingActionBar,
    ArAgingDetailsReport,
    ArAgingSummaryReport,
    CustomElFormItem,
    SupplierAgingReportQuery,
    SupplierAgingReportSearchToolsQuery
  },
  data() {
    const reportParams = urlParams('report', UrlParamFormat.BASE64_JSON) || {};

    return {
      isDownloading: false,
      reportInput: {
        settings: {
          isShortCurrency: false,
          isLandscape: true,
          asOfDate: null
        },
        type: ReportType.SUMMARY,
        filter: {},
        filterToolsState: null,
        ...reportParams
      },
      reportData: null,
      searchTools: null,

      // Consts
      ReportType
    };
  }
};
</script>
<style lang="scss">
@media print {
  .toggle {
    display: none;
  }

  .report-document-box {
    margin: 0 !important;
  }
}
</style>
