<script>
import { getAgingReport } from '@/graphql/accounting/reports/getAgingReport.graphql';
import { QueryMixin } from '@/components/Core/Helpers';
import { InvoiceStatusKeys, TransactionStatus } from '@/constants';

export default {
  mixins: [QueryMixin],
  props: {
    filter: {
      type: Object,
      default: () => {
        return {
          status: {
            '!=': InvoiceStatusKeys.VOID
          }
        };
      }
    }
  },
  query() {
    return {
      query: getAgingReport,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        filter: this.filter
      }
    };
  }
};
</script>
