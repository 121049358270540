<template>
  <div class="dimensions-selection-form flex">
    <div class="label">{{ theTitle }}</div>
    <div class="value ml-2">
      <editable-document-item
        clearable
        :select="{ multiple: !primary, valueKey: 'labelField.value' }"
        :value="primary ? primaryDimension : secondaryDimensions"
        :editable="editable"
        @change="onChange"
      >
        <template slot="select-options">
          <el-option
            v-for="availableDimension in remainingDimensions"
            :key="
              `primary-dimension-` +
                availableDimension.labelField.key +
                '-' +
                availableDimension.labelField.value
            "
            :label="availableDimension.labelField.value"
            :value="availableDimension"
          />
        </template>

        <div v-if="primary ? primaryDimension : secondaryDimensions.length > 0">
          <template v-if="primary">
            {{ primaryDimension.labelField.value }}
          </template>
          <template v-else>
            <div
              v-for="dimension of secondaryDimensions"
              :key="
                'dimension-selected-' +
                  dimension.labelField.key +
                  '-' +
                  dimension.labelField.value
              "
            >
              {{ dimension.labelField.value }}
            </div>
          </template>
        </div>
        <div v-else class="text-dark-silver">
          {{ placeholder || 'Choose Dimension' + (primary ? '' : 's') }}
        </div>
      </editable-document-item>
    </div>
  </div>
</template>

<script>
import EditableDocumentItem from '@/components/Supplier/Billing/Document/EditableDocumentItem';

export default {
  components: { EditableDocumentItem },
  model: {
    prop: 'dimensions',
    event: 'input'
  },
  props: {
    primary: Boolean,
    title: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    availableDimensions: {
      type: Array,
      required: true
    },
    dimensions: {
      type: Array,
      default: null
    },
    editable: Boolean
  },
  computed: {
    theTitle() {
      return (
        this.title || (this.primary ? 'Primary Dimension:' : 'Grouped By:')
      );
    },
    primaryDimension() {
      return this.dimensions[0];
    },
    secondaryDimensions() {
      let dims = [...this.dimensions];
      dims.shift();
      return dims;
    },
    remainingDimensions() {
      if (this.primary) {
        return this.availableDimensions.filter(
          d => !this.secondaryDimensions.includes(d)
        );
      } else {
        return this.availableDimensions.filter(
          d => d !== this.primaryDimension
        );
      }
    }
  },
  methods: {
    onChange(input) {
      let selectedDimensions = [...this.dimensions];

      if (this.primary) {
        if (this.primary) {
          if (!input) {
            selectedDimensions = [];
          } else {
            selectedDimensions[0] = input;
          }
        }
      } else {
        if (input) {
          selectedDimensions = [this.primaryDimension, ...input];
        } else {
          selectedDimensions = [this.primaryDimension];
        }
      }

      this.$emit('input', selectedDimensions);
    }
  }
};
</script>
