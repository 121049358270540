<template>
  <tr class="comparison-table-row" :class="{ 'merged-end-row': mergedRow }">
    <td
      v-for="(column, index) in visibleDimensionColumns"
      :key="'dimension-column-' + column.key"
      class="dimension-column"
      :class="{
        'merged-start-col': mergedRow && index === 0,
        'merged-middle-col': mergedRow && index > 0,
        indented: indented && index === 0
      }"
    >
      {{ record[column.value] }}
    </td>
    <template v-if="comparisons.length > 0">
      <template v-for="comparison in comparisons">
        <td
          v-for="metric in metrics"
          :key="'metric-column-' + comparison.name + '-' + metric.name"
          class="metric-column"
        >
          <template v-if="record.comparisons[comparison.name]">
            {{
              formatField(
                record.comparisons[comparison.name][metric.name],
                metric.format
              )
            }}
          </template>
        </td>
      </template>
    </template>
    <template v-else>
      <td
        v-for="(metric, index) in visibleMetricColumns"
        :key="'metric-column-' + metric.name"
        class="metric-column"
        :class="{
          'merged-middle-col': mergedRow && index < metrics.length - 1,
          'merged-end-col': mergedRow && index === metrics.length - 1,
          indented: indented && index === 0
        }"
      >
        <div>
          {{ formatField(record[metric.name], metric.format) }}
        </div>
      </td>
    </template>
  </tr>
</template>

<script>
import ComparisonReportMixin from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportMixin';

export default {
  mixins: [ComparisonReportMixin],
  props: {
    // Indicates the styling should be merged with the previous row
    mergedRow: Boolean,
    indented: Boolean,
    record: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.merged-row {
  .dimension-column {
    border-top: none;
    border-right: none;
  }
}
</style>
