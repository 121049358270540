<template>
  <table class="doc-table w-full">
    <comparison-report-table-head
      indented
      :search-tools="searchTools"
      :settings="settings"
      :dimensions="dimensions"
      :metrics="metrics"
      :comparisons="comparisons"
      :sort="sort"
      @input="$emit('input', $event)"
      @sort="$emit('sort', $event)"
    />
    <tbody class="dimension-columns-no-borders">
      <template v-for="group in groupedData">
        <tr
          :key="'comparison-group-header-' + group.key"
          class="merged-start-row"
        >
          <template v-if="comparisons.length > 0">
            <td :colspan="visibleDimensionColumns.length" class="font-semibold">
              {{ group.name }}
            </td>
            <td
              v-for="i in metrics.length * comparisons.length"
              :key="'td-placeholder-' + i"
            />
          </template>
          <template v-else>
            <td
              :colspan="visibleDimensionColumns.length + metrics.length"
              class="font-semibold"
            >
              {{ group.name }}
            </td>
          </template>
        </tr>
        <comparison-table-row
          v-for="record in group.records"
          :key="'comparison-record-' + record.id"
          indented
          merged-row
          :record="record"
          :settings="settings"
          :dimensions="dimensions"
          :metrics="metrics"
          :comparisons="comparisons"
        />
        <tr
          :key="'comparison-group-footer-' + group.key"
          class="details-record-footer"
        >
          <td :colspan="totalForColspan" class="font-semibold merged-start-col">
            Total for {{ group.name }}
          </td>
          <template v-if="comparisons.length > 0">
            <template v-for="comparison of comparisons">
              <template v-for="metric of metrics">
                <td
                  :key="
                    'group-' +
                      group.name +
                      '-comparison-' +
                      comparison.name +
                      '-total-' +
                      metric.name
                  "
                >
                  {{
                    formatField(
                      group.totals[comparison.name][metric.name],
                      metric.format
                    )
                  }}
                </td>
              </template>
            </template>
          </template>
          <template v-else>
            <template v-for="(metric, index) of metrics">
              <td
                v-if="index >= totalsSkipMetricCount"
                :key="'group-' + group.name + '-total-' + metric.name"
                :class="{
                  'merged-middle-col': index < metrics.length - 1,
                  'merged-end-col': index === metrics.length - 1
                }"
              >
                {{ formatField(group.totals[metric.name], metric.format) }}
              </td>
            </template>
          </template>
        </tr>
      </template>
    </tbody>
    <comparison-report-table-footer
      :data="data"
      :settings="settings"
      :dimensions="dimensions"
      :metrics="metrics"
      :comparisons="comparisons"
    />
  </table>
</template>

<script>
import ComparisonReportMixin from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportMixin';
import ComparisonTableRow from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonTableRow';
import ComparisonReportTableHead from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportTableHead';

import { ReportAggregate, ReportFormat } from '@/constants';
import ComparisonReportTableFooter from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportTableFooter';

export default {
  components: {
    ComparisonReportTableFooter,
    ComparisonReportTableHead,
    ComparisonTableRow
  },
  mixins: [ComparisonReportMixin],
  props: {
    searchTools: {
      type: Array,
      default: null
    },
    data: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      ReportFormat
    };
  },
  computed: {
    totalForColspan() {
      return this.visibleDimensionColumns.length + this.totalsSkipMetricCount;
    },

    /**
     * The total # of metric columns to skip in to optimize the display text for
     * totals column (purely cosmetic)
     *
     * @returns {number}
     */
    totalsSkipMetricCount() {
      let count = 0;

      if (this.comparisons.length === 0) {
        for (let metric of this.metrics) {
          if (metric.aggregate === ReportAggregate.NONE) {
            count++;
          } else {
            break;
          }
        }
      }

      return count;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

tr.details-record-footer {
  td {
    background: $color-light-silver;
  }
}
</style>
