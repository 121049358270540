<template>
  <div class="supplier-transaction-report flex">
    <supplier-report-sidebar
      v-model="reportInput.settings"
      class="flex-shrink-0"
    />

    <div class="flex-grow ml-5 report-document-box">
      <supplier-transaction-report-search-tools-query
        @data="
          searchTools =
            $event.supplier.accountingReports.transactionReport.searchTools
        "
      />

      <supplier-transaction-report-query
        v-if="reportInputValid"
        :loading.sync="isLoadingReport"
        :dimensions="reportInput.dimensions"
        :metrics="filteredMetrics"
        :comparisons="reportInput.comparisons"
        :filter="reportInput.filter"
        @data="
          reportData =
            $event.supplier.accountingReports.transactionReport.comparison
        "
      />

      <div
        class="transition-all transition-d-.5"
        :style="{
          maxWidth: reportInput.settings.isLandscape ? '11in' : '8.5in'
        }"
      >
        <transition name="fade">
          <comparison-report-action-bar
            name="TransactionReport"
            url="/v3/supplier/accounting/reports/transaction"
            :disabled="isLoadingReport || !reportData"
            :data="reportData"
            report-id="transaction"
            :comparisons="reportInput.comparisons"
            :dimensions="reportInput.dimensions"
            :filter="reportInput.filter"
            :metrics="filteredMetrics"
            :settings="reportInput.settings"
            :sort="reportInput.sort"
            :filter-tools-state="reportInput.filterToolsState"
            class="my-5"
            @downloading="isDownloading = $event"
          />
        </transition>

        <comparison-report-document
          shadow
          :allow-comparisons="false"
          title="Transaction Report"
          :can-change-primary="false"
          :available-dimensions="availableDimensions"
          :comparisons="reportInput.comparisons"
          :dimensions="reportInput.dimensions"
          :filter="reportInput.filter"
          :metrics="filteredMetrics"
          :settings="reportInput.settings"
          :sort="reportInput.sort"
          :loading-body="isLoadingReport"
          :loading="!searchTools"
          :data="reportData"
          :search-tools="searchTools"
          :filter-tools-state.sync="reportInput.filterToolsState"
          :exporting="isDownloading"
          @sort="reportInput.sort = $event"
          @settings="reportInput.settings = $event"
          @input="reportInput = { ...reportInput, ...$event }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ComparisonReportActionBar from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportActionBar';
import ComparisonReportDocument from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportDocument';
import SupplierReportSidebar from '@/components/Supplier/Accounting/Reports/SupplierReportSidebar';
import SupplierTransactionReportQuery from '@/components/Queries/Accounting/Reports/SupplierTransactionReportQuery';
import SupplierTransactionReportSearchToolsQuery from '@/components/Queries/Accounting/Reports/SupplierTransactionReportSearchToolsQuery';

import { FeatureFlags, ReportAggregate, ReportFormat } from '@/constants';
import { UrlParamFormat, urlParams } from '@/utils/helpers';

export default {
  components: {
    ComparisonReportActionBar,
    ComparisonReportDocument,
    SupplierReportSidebar,
    SupplierTransactionReportQuery,
    SupplierTransactionReportSearchToolsQuery
  },
  data() {
    const reportParams = urlParams('report', UrlParamFormat.BASE64_JSON) || {};

    return {
      isLoadingReport: false,
      isDownloading: false,
      reportData: null,
      searchTools: null,
      reportInput: {
        settings: {
          isShortCurrency: false,
          isLandscape: true
        },
        dimensions: [
          {
            labelField: {
              key: 'id',
              value: 'Transaction ID',
              hidden: true
            },
            keyField: {
              key: 'id',
              value: 'ID'
            },
            sortable: true
          }
        ],
        metrics: [
          {
            name: 'Date',
            field: 'date',
            aggregate: ReportAggregate.NONE,
            format: ReportFormat.DATE,
            sortable: true
          },
          {
            name: 'Customer ID',
            field: 'customer.id',
            aggregate: ReportAggregate.NONE,
            sortable: true
          },
          {
            name: 'Customer',
            field: 'customer.buyer.buyer_company',
            aggregate: ReportAggregate.NONE,
            sortable: true
          },
          {
            name: 'Customer Type',
            field: 'customer.type',
            aggregate: ReportAggregate.NONE,
            sortable: true
          },
          {
            name: 'Type',
            field: 'type',
            aggregate: ReportAggregate.NONE,
            sortable: true
          },
          {
            name: 'Method',
            field: 'paymentMethod.name',
            aggregate: ReportAggregate.NONE,
            sortable: true
          },
          {
            name: 'Status',
            field: 'status',
            aggregate: ReportAggregate.NONE,
            sortable: true
          },
          {
            name: 'Amount',
            field: 'amount',
            aggregate: ReportAggregate.SUM,
            format: ReportFormat.CURRENCY,
            sortable: true
          },
          {
            name: 'Notes',
            field: 'description',
            aggregate: ReportAggregate.NONE,
            sortable: true
          }
        ],
        comparisons: [],
        sort: [],
        filter: {},
        filterToolsState: null,
        ...reportParams
      }
    };
  },
  computed: {
    reportInputValid() {
      return (
        this.reportInput.dimensions.length > 0 &&
        this.reportInput.metrics.length > 0
      );
    },
    dimensionColumns() {
      let columns = [];

      for (let dimension of this.reportInput.dimensions) {
        columns.push(dimension.labelField);
        columns.push(dimension.keyField);
      }

      return columns;
    },
    filteredMetrics() {
      return this.reportInput.metrics.filter(
        m =>
          !this.dimensionColumns.find(d => d.key === m.field && !d.showMetric)
      );
    },
    availableDimensions() {
      const reps = [];

      if (this.$feature(FeatureFlags.REPORTING_REPS_DIMENSION)) {
        reps.push({
          labelField: {
            key: 'customer.reps.email',
            value: 'Rep Email'
          },
          keyField: {
            key: 'customer.reps.id',
            value: 'Rep ID',
            hidden: true
          },
          allowMultipleCardinality: true,
          sortable: true
        });
      }

      return [
        {
          labelField: {
            key: 'buyer.buyer_company',
            value: 'Customer'
          },
          keyField: {
            key: 'buyer.id',
            value: 'Customer ID'
          },
          sortable: true
        },
        ...reps,
        {
          labelField: {
            key: 'customer.type',
            value: 'Customer Type'
          },
          keyField: {
            key: 'customer.type',
            value: 'Customer Type ID',
            hidden: true
          },
          sortable: true
        },
        {
          labelField: {
            key: 'orderLineItem.orderProduct.medium_name',
            value: 'Medium'
          },
          keyField: {
            key: 'orderLineItem.orderProduct.medium_name',
            value: 'Medium ID',
            hidden: true
          },
          sortable: true
        },
        {
          labelField: {
            key: 'orderLineItem.orderProduct.property_name',
            value: 'Property'
          },
          keyField: {
            key: 'orderLineItem.orderProduct.property_name',
            value: 'Property ID',
            hidden: true
          },
          sortable: true
        },
        {
          labelField: {
            key: 'orderLineItem.orderProduct.collection_name',
            value: 'Collection'
          },
          keyField: {
            key: 'orderLineItem.orderProduct.collection_name',
            value: 'Collection ID',
            hidden: true
          },
          sortable: true
        },
        {
          labelField: {
            key: 'orderLineItem.orderProduct.product_name',
            value: 'Product'
          },
          keyField: {
            key: 'orderLineItem.orderProduct.product_name',
            value: 'Product ID',
            hidden: true
          },
          sortable: true
        },
        {
          labelField: {
            key: 'orderLineItem.orderProduct.product_variant_name',
            value: 'Variant'
          },
          keyField: {
            key: 'orderLineItem.orderProduct.product_variant_id',
            value: 'Variant ID',
            hidden: true
          },
          sortable: true
        },
        {
          labelField: {
            key: 'date',
            value: 'Date'
          },
          keyField: {
            key: 'date',
            value: 'Date ID'
          },
          aggregate: ReportAggregate.DATE,
          format: ReportFormat.DATE,
          sortable: true
        },
        {
          labelField: {
            key: 'date',
            value: 'Week',
            showMetric: true
          },
          keyField: {
            key: "DATE_FORMAT(date, '%U %Y')",
            value: 'Week ID'
          },
          format: ReportFormat.WEEK,
          sortable: true
        },
        {
          labelField: {
            key: 'date',
            value: 'Month',
            showMetric: true
          },
          keyField: {
            key: "DATE_FORMAT(date, '%M %Y')",
            value: 'Month ID'
          },
          format: ReportFormat.MONTH,
          sortable: true
        },
        {
          labelField: {
            key: 'date',
            value: 'Year',
            showMetric: true
          },
          keyField: {
            key: "DATE_FORMAT(date, '%Y')",
            value: 'Year ID'
          },
          format: ReportFormat.YEAR,
          sortable: true
        }
      ];
    }
  }
};
</script>
<style lang="scss">
@media print {
  .report-document-box {
    margin: 0 !important;
  }
}
</style>
