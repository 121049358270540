<template>
  <thead class="comparison-report-table-head">
    <tr v-if="comparisons.length > 0" class="doc-table-pre-header">
      <td :colspan="visibleDimensionColumns.length"></td>
      <td
        v-for="(comparison, comparisonIndex) in comparisons"
        :key="'comparison-column-' + comparison.name"
        :colspan="visibleMetricColumns.length"
        class="comparison-column"
      >
        <div class="flex">
          <div class="flex-grow">{{ comparison.name }}</div>
          <div class="flex flex-end">
            <div>
              <el-popover content="Modify this comparison filter">
                <a
                  slot="reference"
                  class="text-blue edit-comparison comparison-action transition-all"
                >
                  <icon :icon="editIcon" />
                </a>

                <comparison-form
                  class="max-w-sm"
                  submit-text="Save Comparison"
                  :search-tools="searchTools"
                  :input="comparison"
                  :loading="isLoadingComparison"
                  @save="editComparison(comparisonIndex, $event)"
                />
              </el-popover>
            </div>
            <div>
              <a
                class="text-red remove-comparison comparison-action transition-all ml-3"
                @click="removeComparison(comparison)"
              >
                <icon :icon="removeIcon" />
              </a>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td
        v-for="(column, index) in visibleDimensionColumns"
        :key="'dimension-column-' + column.key"
        :class="{ indented: indented && index === 0 }"
      >
        <table-head-column
          :column="column.value"
          :sortable="column.sortable"
          :sort="sort"
          @sort="$emit('sort', $event)"
        />
      </td>
      <template v-if="comparisons.length > 0">
        <template v-for="comparison in comparisons">
          <td
            v-for="metric in visibleMetricColumns"
            :key="'metric-column-' + comparison.name + '-' + metric.name"
          >
            <table-head-column
              :column="comparison.name + ':' + metric.name"
              :label="metric.name"
              :sortable="metric.sortable"
              :sort="sort"
              @sort="$emit('sort', $event)"
            />
          </td>
        </template>
      </template>
      <template v-else>
        <td
          v-for="metric in visibleMetricColumns"
          :key="'metric-column-' + metric.name"
        >
          <table-head-column
            :column="metric.name"
            :label="metric.name"
            :sortable="metric.sortable"
            :sort="sort"
            @sort="$emit('sort', $event)"
          />
        </td>
      </template>
    </tr>
  </thead>
</template>

<script>
import { cancel as removeIcon, pencil as editIcon } from '@/vendor/icons';
import ComparisonReportMixin from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportMixin';
import TableHeadColumn from '@/components/Supplier/Accounting/Reports/ComparisonReport/TableHeadColumn';
import ComparisonForm from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonForm';

export default {
  components: { ComparisonForm, TableHeadColumn },
  mixins: [ComparisonReportMixin],
  props: {
    indented: Boolean,
    searchTools: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isLoadingComparison: false,

      // Icons
      editIcon,
      removeIcon
    };
  },
  methods: {
    editComparison(index, comparison) {
      this.isLoadingComparison = true;

      let comparisons = [...this.comparisons];

      comparisons[index] = comparison;

      this.$emit('input', { comparisons });

      setTimeout(() => (this.isLoadingComparison = false), 1000);
    },
    removeComparison(comparison) {
      this.$emit('input', {
        comparisons: [
          ...this.comparisons.filter(c => c.name !== comparison.name)
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.comparison-column {
  .comparison-action {
    opacity: 0;
  }

  &:hover {
    .comparison-action {
      opacity: 1;
    }
  }
}
</style>
