<template>
  <report-document
    class="ar-aging-details-report"
    v-bind="$props"
    :landscape="settings.isLandscape"
    :search-tools="undefined"
    :data="undefined"
    :settings="undefined"
    show-menu
    @hover="isHovering = $event"
    @update:landscape="$emit('settings', { ...settings, isLandscape: $event })"
  >
    <div slot="subtitle">
      <ar-aging-report-subtitle
        :editable="isHovering"
        :supplier="supplier"
        :settings="settings"
        @settings="$emit('settings', $event)"
      />
    </div>
    <div slot="header-right">
      <report-filter-table
        :filterable="isHovering"
        :search-tools="searchTools"
        :tools-state="filterToolsState"
        @update:tools-state="$emit('update:filter-tools-state', $event)"
        @filter="$emit('filter', $event)"
      />
    </div>

    <div v-if="data" class="mt-8 w-full overflow-auto">
      <table class="doc-table w-full">
        <thead>
          <tr>
            <td>Date</td>
            <td>Invoice</td>
            <td>Customer</td>
            <td>Due Date</td>
            <td>Amount</td>
            <td>Balance</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="record in detailsData">
            <tr
              :key="'detail-record-label-' + record.label"
              class="detail-record-label sub-row-header"
            >
              <td>{{ record.label }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr
              v-for="invoice of record.invoices"
              :key="'detail-record-invoice-' + invoice.id"
              class="detail-record-invoice sub-row"
            >
              <td>{{ invoice.transaction_date | date }}</td>
              <td class="min-w-5.5">{{ invoice.ref }}</td>
              <td>{{ invoice.buyer.buyer_company }}</td>
              <td>{{ invoice.due_date | date }}</td>
              <td>{{ invoice.amount | currency({ short }) }}</td>
              <td>{{ invoice.balance | currency({ short }) }}</td>
            </tr>
            <tr
              :key="'detail-record-footer-' + record.label"
              class="detail-record-footer footer-row"
            >
              <td colspan="4">Total for {{ record.label }}</td>
              <td>{{ record.total_amount | currency({ short }) }}</td>
              <td>{{ record.total_balance | currency({ short }) }}</td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td class="font-bold" colspan="4">Total</td>
            <td class="font-bold">
              {{ detailsTotals.amount | currency({ short }) }}
            </td>
            <td class="font-bold">
              {{ detailsTotals.balance | currency({ short }) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </report-document>
</template>

<script>
import ReportDocument from '@/components/Supplier/Accounting/Reports/ReportDocument';
import ArAgingReportMixin from '@/components/Supplier/Accounting/Reports/ArAgingReport/ArAgingReportMixin';
import ReportFilterTable from '@/components/Supplier/Accounting/Reports/ReportFilterTable';
import ArAgingReportSubtitle from '@/components/Supplier/Accounting/Reports/ArAgingReport/ArAgingReportSubtitle';

export default {
  components: { ArAgingReportSubtitle, ReportFilterTable, ReportDocument },
  mixins: [ArAgingReportMixin],
  props: {
    ...ReportDocument.props,
    title: {
      type: String,
      default: 'AR Aging Details'
    },
    data: {
      type: Array,
      default: null
    },
    searchTools: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isHovering: false
    };
  },
  computed: {
    supplier() {
      return this.$store.getters['auth/supplier'];
    },
    short() {
      return this.settings.isShortCurrency;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables.scss';

tr.details-record-footer {
  td {
    background: $color-light-silver;
  }
}
</style>
