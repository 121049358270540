<script>
import moment from 'moment';
import { groupBy, mapValues } from 'lodash';
import { currency, date } from '@/utils/filters';

export default {
  props: {
    data: {
      type: Array,
      default: null
    },
    sort: {
      type: Array,
      default: () => []
    },
    settings: {
      type: Object,
      required: true
    },
    filterToolsState: {
      type: [Object, Array],
      default: null
    }
  },
  computed: {
    asOfDate() {
      return moment(this.settings.asOfDate || undefined).endOf('day');
    },
    overdueCategories() {
      return {
        Current: 0,
        '1-30': 30,
        '31-60': 60,
        '61-90': 90,
        '91-120': 120,
        '120+': Infinity
      };
    },
    invoicesByCustomer() {
      return Object.values(groupBy(this.data, 'buyer.id'));
    },
    /**
     * Computes the summarized data
     * @returns {[]}
     */
    summaryData() {
      const reportData = [];

      for (let customerInvoices of this.invoicesByCustomer) {
        const first = customerInvoices[0];
        const record = {
          id: first.buyer.id,
          customer: first.buyer.buyer_company,
          defaultTerms:
            // If no customer is set, this is probably Flytedesk as the customer
            (first.customer && first.customer.terms.text) || '90 days',
          ...mapValues(this.overdueCategories, () => 0),
          total: 0
        };

        for (let invoice of customerInvoices) {
          let category = this.resolveOverdueCategory(invoice);

          if (category) {
            let amountOverdue = this.resolveAmountDue(invoice);

            record[category] += amountOverdue;
            record.total += amountOverdue;
          }
        }

        // If the Total due for the record is not $0, we want to show this record
        if (record.total) {
          reportData.push(record);
        }
      }

      return reportData;
    },
    detailsData() {
      const reportDataMap = new Map(
        Object.keys(this.overdueCategories).map(name => {
          return [
            name,
            {
              label: name + (name === 'Current' ? '' : ' days past due'),
              invoices: [],
              total_amount: 0,
              total_balance: 0
            }
          ];
        })
      );

      if (this.data) {
        for (let invoice of this.data) {
          const category = this.resolveOverdueCategory(invoice);

          if (category) {
            const categoryItem = reportDataMap.get(category);

            let balance = this.resolveAmountDue(invoice);

            categoryItem.total_amount += +invoice.amount;
            categoryItem.total_balance += balance;
            categoryItem.invoices.push({ ...invoice, balance });
          }
        }
      }

      return Array.from(reportDataMap.values());
    },
    /**
     * Flattens the details data into an array
     * @returns {[]}
     */
    detailsDataFlattened() {
      const records = [];

      for (let record of this.detailsData) {
        records.push({
          Date: record.label,
          Invoice: '',
          Customer: '',
          'Due Date': '',
          Amount: '',
          Balance: ''
        });

        for (let invoice of record.invoices) {
          records.push({
            Date: date(invoice.transaction_date),
            Invoice: invoice.ref,
            Customer: invoice.buyer.buyer_company,
            'Due Date': date(invoice.due_date),
            Amount: currency(invoice.amount),
            Balance: currency(invoice.balance)
          });
        }

        records.push({
          Date: 'Total for ' + record.label,
          Invoice: '',
          Customer: '',
          'Due Date': '',
          Amount: record.total_amount,
          Balance: record.total_balance
        });
      }

      return records;
    },
    summaryTotals() {
      let totals = {};

      for (let record of this.summaryData) {
        for (let category of Object.keys(this.overdueCategories)) {
          totals[category] = (totals[category] || 0) + record[category];
        }

        totals.total = (totals.total || 0) + record.total;
      }

      return totals;
    },
    detailsTotals() {
      let totals = {
        amount: 0,
        balance: 0
      };

      for (let record of this.detailsData) {
        totals.amount += record.total_amount;
        totals.balance += record.total_balance;
      }

      return totals;
    }
  },
  methods: {
    resolveAmountDue(invoice) {
      let remainderDue = +invoice.amount;

      for (let transaction of invoice.transactions) {
        if (this.asOfDate.isAfter(transaction.date)) {
          remainderDue -= +transaction.amount;
        }
      }

      // XXX: To handle rounding errors in JS, round off anything less than a penny
      return Math.abs(remainderDue) < 0.009 ? 0 : remainderDue;
    },
    resolveOverdueCategory(invoice) {
      // If the As Of Date is after the date the invoice was created, we can ignore this invoice
      if (this.asOfDate.isBefore(invoice.transaction_date)) {
        return false;
      }

      const daysOverdue = this.asOfDate.diff(invoice.due_date, 'days');

      const overdueCategories = Object.keys(this.overdueCategories);

      for (let overdueCategory of overdueCategories) {
        const days = this.overdueCategories[overdueCategory];

        if (daysOverdue <= days) {
          return overdueCategory;
        }
      }

      throw new Error(
        'There was no category found for ' +
          daysOverdue +
          ' days overdue: ' +
          date
      );
    }
  }
};
</script>
