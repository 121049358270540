<template>
  <base-document
    class="report-document"
    v-bind="$props"
    @hover="$emit('hover', $event)"
    @update:hovering="$emit('update:hovering', $event)"
    @update:landscape="$emit('update:landscape', $event)"
    @unlock="$emit('unlock', $event)"
  >
    <template slot="header">
      <slot name="header">
        <div class="flex justify-space-between mb-5">
          <div class="text-left flex-grow">
            <h1 v-if="title">{{ title }}</h1>
            <div class="subtitle mt-2">
              <slot name="subtitle">
                <div v-if="subtitle">
                  {{ subtitle }}
                </div>
              </slot>
            </div>
          </div>
          <div class="text-right">
            <slot name="header-right" />
          </div>
        </div>
      </slot>
    </template>

    <slot />
  </base-document>
</template>

<script>
import BaseDocument from '@/components/Core/Documents/BaseDocument';

export default {
  components: {
    BaseDocument
  },
  props: {
    ...BaseDocument.props,
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables.scss';
</style>
