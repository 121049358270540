<template>
  <report-document
    class="comparison-report-document relative"
    v-bind="{
      ...$props,
      landscape: settings.isLandscape,
      data: undefined,
      searchTools: undefined,
      settings: undefined,
      metrics: undefined,
      dimensions: undefined,
      comparisons: undefined,
      sort: undefined
    }"
    show-menu
    @hover="isHovering = $event"
    @update:landscape="$emit('settings', { ...settings, isLandscape: $event })"
  >
    <div slot="subtitle">
      <comparison-report-subtitle
        :can-change-primary="canChangePrimary"
        :available-dimensions="availableDimensions"
        :dimensions="dimensions"
        :editable="isHovering"
        :supplier="supplier"
        @input="$emit('input', { dimensions: $event })"
      />
    </div>
    <div slot="header-right">
      <report-filter-table
        :filterable="isHovering"
        :search-tools="searchTools"
        :tools-state="filterToolsState"
        @update:tools-state="$emit('update:filter-tools-state', $event)"
        @filter="$emit('input', { filter: $event })"
      />
    </div>

    <div class="relative mt-8 w-full">
      <transition name="fade">
        <div
          v-if="data && dimensions.length > 0"
          :key="'comparison-report-table'"
          class="w-full overflow-auto"
        >
          <transition name="fade" mode="out-in">
            <comparison-report-table
              v-if="secondaryDimensions.length === 0"
              :data="data"
              :search-tools="searchTools"
              :settings="settings"
              :dimensions="dimensions"
              :metrics="metrics"
              :comparisons="comparisons"
              :sort="sort"
              @sort="$emit('sort', $event)"
              @input="$emit('input', $event)"
            />

            <comparison-details-report-table
              v-else
              :data="data"
              :search-tools="searchTools"
              :settings="settings"
              :dimensions="dimensions"
              :metrics="metrics"
              :comparisons="comparisons"
              :sort="sort"
              @sort="$emit('sort', $event)"
              @input="$emit('input', $event)"
            />
          </transition>
        </div>
      </transition>

      <editable-document-item
        v-if="canAddComparison"
        popover
        :editable="isHovering"
        :show-icon="false"
        class="add-comparison-item"
      >
        <el-tooltip slot="reference" effect="light">
          <div slot="content" class="text-base">
            <div>Add an additional comparison column</div>
            <div class="font-bold text-center mt-2">
              {{ comparisons.length }} / 4 added
            </div>
          </div>
          <a class="add-comparison-icon">
            <icon :icon="addIcon" />
          </a>
        </el-tooltip>

        <comparison-form
          class="max-w-sm"
          submit-text="Add Comparison"
          :search-tools="searchTools"
          @save="addComparison"
        />
      </editable-document-item>
    </div>
  </report-document>
</template>

<script>
import ReportDocument from '@/components/Supplier/Accounting/Reports/ReportDocument';
import ComparisonReportMixin from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportMixin';
import ReportFilterTable from '@/components/Supplier/Accounting/Reports/ReportFilterTable';

import ComparisonReportSubtitle from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportSubtitle';
import EditableDocumentItem from '@/components/Supplier/Billing/Document/EditableDocumentItem';
import { plus as addIcon } from '@/vendor/icons';
import ComparisonReportTable from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportTable';
import ComparisonDetailsReportTable from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonDetailsReportTable';
import ComparisonForm from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonForm';

export default {
  components: {
    ComparisonForm,
    ComparisonDetailsReportTable,
    ComparisonReportTable,
    EditableDocumentItem,
    ComparisonReportSubtitle,
    ReportFilterTable,
    ReportDocument
  },
  mixins: [ComparisonReportMixin],
  props: {
    ...ReportDocument.props,
    canChangePrimary: {
      type: Boolean,
      default: true
    },
    allowComparisons: {
      type: Boolean,
      default: true
    },
    availableDimensions: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: 'Comparison Summary'
    },
    searchTools: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isHovering: false,

      // Icons
      addIcon
    };
  },
  computed: {
    canAddComparison() {
      return (
        this.allowComparisons &&
        this.comparisons.length <= 3 &&
        this.data &&
        this.dimensions.length > 0
      );
    },
    supplier() {
      return this.$store.getters['auth/supplier'];
    }
  },
  methods: {
    addComparison(comparison) {
      this.$emit('input', {
        comparisons: [...this.comparisons, { ...comparison }]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.add-comparison-item {
  position: absolute;
  top: 0;
  left: 100%;
  width: 3em;

  .add-comparison-icon {
    padding: 0.3em 0.6em;
    width: 3em;
    text-align: center;
    color: $color-blue;
    border: 1px solid $color-gray;
    border-left-width: 0;
    overflow: hidden;
  }
}
</style>
