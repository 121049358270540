<template>
  <el-tooltip effect="light" :content="tooltip" :disabled="!tooltip">
    <div class="action-button">
      <el-button
        class="el-button flex justify-center items-center"
        :class="{ [styleClass]: true }"
        :disabled="disabled || isActive"
        @click="$emit('click')"
      >
        <slot name="icon">
          <icon
            :icon="icon"
            :class="{ [iconClass]: true, 'animated loop tada': isActive }"
            :spin="isActive"
          />
        </slot>
      </el-button>
    </div>
  </el-tooltip>
</template>

<script>
import { shareSquareO } from '@/vendor/icons';

export default {
  props: {
    tooltip: {
      type: String,
      default: null
    },
    styleClass: {
      type: String,
      default: 'border-gray group hover:border-dark-silver w-14 h-14'
    },
    isActive: Boolean,
    disabled: Boolean,
    iconClass: {
      type: String,
      default: 'icon-xl text-dark-silver group-hover:text-gunmetal'
    },
    icon: {
      type: Object,
      default: () => shareSquareO
    }
  }
};
</script>

<style lang="scss" scoped>
.action-button {
  .el-button {
    pointer-events: initial !important;
  }
}
</style>
