<template>
  <report-action-bar
    class="flex justify-end"
    show-copy
    show-export
    show-print
    show-download
    :download-name="downloadName"
    :export-name="exportName"
    :export-data="exportData"
    :report-input="reportInput"
    :document-url="documentUrl"
    :disabled="disabled"
    @downloading="$emit('downloading', $event)"
    @exporting="$emit('exporting', $event)"
  />
</template>

<script>
import ArAgingReportMixin from '@/components/Supplier/Accounting/Reports/ArAgingReport/ArAgingReportMixin';
import ReportActionBar from '@/components/Supplier/Accounting/Reports/ReportActionBar';

import { ReportType } from '@/constants';
import { cleanToolsState } from '@/utils/helpers/searchTools';

export default {
  components: { ReportActionBar },
  mixins: [ArAgingReportMixin],
  props: {
    type: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      default: null
    },
    disabled: Boolean
  },
  computed: {
    reportInput() {
      return {
        reportId: 'ar-aging',
        reportType: this.type,
        filter: this.filter,
        sort: this.sort,
        settings: this.settings,
        filterToolsState: cleanToolsState(this.filterToolsState)
      };
    },
    documentUrl() {
      return (
        '/v3/supplier/accounting/reports/ar-aging?report=' +
        btoa(
          JSON.stringify({
            settings: this.settings,
            type: this.type,
            filter: this.filter,
            filterToolsState: cleanToolsState(this.filterToolsState)
          })
        )
      );
    },
    exportData() {
      return this.type === ReportType.SUMMARY
        ? this.summaryData
        : this.detailsDataFlattened;
    },
    exportName() {
      return this.type === ReportType.SUMMARY
        ? 'AR_Aging_Summary.csv'
        : 'AR_Aging_Details.csv';
    },
    downloadName() {
      return this.type === ReportType.SUMMARY
        ? 'AR_Aging_Summary.pdf'
        : 'AR_Aging_Details.pdf';
    }
  }
};
</script>

<style lang="scss">
@media print {
  .ar-aging-action-bar {
    display: none !important;
  }
}
</style>
