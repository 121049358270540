<template>
  <div>
    <SupplierReportExportQuery
      v-if="isMassExporting"
      :input="computedReportInput"
      :options="{ fetchPolicy: 'network-only' }"
      @result="onQueryResult"
    />

    <confirm-dialog
      title="Download Individual PDFs"
      confirm-text="Export PDFs"
      :is-saving="isMassExporting"
      :disabled="!input.dimension"
      @confirm="isMassExporting = true"
      @close="$emit('close')"
    >
      <div class="font-semibold text-base">
        Download multiple PDFs broken out by the chosen dimension.
      </div>
      <div class="text-dark-silver">
        For example, if you choose Customers, the export will create 1 PDF
        report for each customer.
      </div>
      <div class="mt-5">
        <el-select v-model="input.dimension">
          <el-option
            v-for="option in dimensionOptions"
            :key="'dimension-option-' + option.label"
            :label="option.label"
            :value="option"
          />
        </el-select>
      </div>
      <div class="mt-5">
        <b>NOTE:</b>
        It may take a while to complete the export. When the export is complete,
        you will receive an email from flytebot to <a>{{ userEmail }}</a> with a
        zip file attachment containing all the individual PDFs.
      </div>
    </confirm-dialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import SupplierReportExportQuery from '@/components/Queries/Accounting/Reports/SupplierReportExportQuery';
import { ReportExportDimensions } from '@/constants';

export default {
  components: {
    SupplierReportExportQuery,
    ConfirmDialog
  },
  props: {
    reportInput: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // State
      isMassExporting: false,
      input: {
        dimension: ReportExportDimensions[0]
      },
      dimensionOptions: ReportExportDimensions
    };
  },
  computed: {
    computedReportInput() {
      return {
        ...this.reportInput,
        fileLabel: this.input.dimension.label,
        dimension: this.input.dimension.value
      };
    },
    userEmail() {
      return this.$store.getters['auth/user'].email;
    }
  },
  methods: {
    onQueryResult() {
      this.isMassExporting = false;
      this.$message.success(
        'Your PDF reports are being generated! Check your email later to download the zip file with your reports.'
      );
      this.$emit('close');
    }
  }
};
</script>
