<template>
  <table
    class="report-filter-table doc-table transition-all"
    :style="{ opacity: isVisible ? '1' : '0' }"
  >
    <thead>
      <tr>
        <td colspan="2">
          <div class="flex">
            <el-popover class="flex-grow">
              <div slot="reference" class="flex cursor-pointer no-outline">
                <div class="report-filter-title mr-3">{{ title }}</div>

                <a>
                  <icon :icon="filterIcon" />
                </a>
              </div>

              <search-tools-form
                v-if="searchTools && !isResettingForm"
                :tools="searchTools"
                :tools-state="toolsState"
                class="w-128"
                @update:tools-state="$emit('update:tools-state', $event)"
                @filter="$emit('filter', $event)"
              />
            </el-popover>
            <div v-if="toolsState">
              <a class="text-dark-silver" @click="onClearFilters">
                clear
              </a>
            </div>
          </div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="searchToolState in searchToolsStateFormatted"
        :key="'tool-state-' + searchToolState.label"
      >
        <td>{{ searchToolState.label }}</td>
        <td>{{ searchToolState.displayValue }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import SearchToolsForm from '@/components/Core/SearchToolsForm';
import { filter as filterIcon } from '@/vendor/icons';

export default {
  components: { SearchToolsForm },
  props: {
    disabled: Boolean,
    filterable: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Filters'
    },
    searchTools: {
      type: Array,
      default: null
    },
    toolsState: {
      type: [Object, Array],
      default: null
    }
  },
  data() {
    return {
      // XXX: This is a hack to get around searchTools not respecting external changes to the selection state
      isResettingForm: false,

      // Icons
      filterIcon
    };
  },
  computed: {
    searchToolsStateFormatted() {
      if (this.toolsState) {
        let formatted = [];

        for (let group of Object.values(this.toolsState)) {
          formatted = formatted.concat(Object.values(group));
        }

        return formatted;
      } else {
        return null;
      }
    },
    isVisible() {
      return this.disabled
        ? false
        : this.filterable ||
            (this.toolsState && Object.keys(this.toolsState).length > 0);
    }
  },
  methods: {
    onClearFilters() {
      this.$emit('filter', {});
      this.$emit('update:tools-state', null);

      // XXX: To get the form to properly reset
      this.isResettingForm = true;

      setTimeout(() => {
        this.isResettingForm = false;
      }, 100);
    }
  }
};
</script>
