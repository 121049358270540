<script>
import { getAgingReportSearchTools } from '@/graphql/accounting/reports/getAgingReport.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  query() {
    return {
      query: getAgingReportSearchTools,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
