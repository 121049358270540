<script>
import { getTransactionReport } from '@/graphql/accounting/reports/getTransactionReport.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    dimensions: {
      type: Array,
      required: true
    },
    metrics: {
      type: Array,
      required: true
    },
    comparisons: {
      type: Array,
      required: true
    },
    filter: {
      type: Object,
      default: null
    }
  },
  query() {
    return {
      query: getTransactionReport,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        input: {
          dimensions: this.dimensions,
          metrics: this.metrics,
          // No comparisons for now for transaction reports
          comparisons: [],
          filter: this.filter
        }
      }
    };
  }
};
</script>
