<template>
  <table class="doc-table w-full">
    <comparison-report-table-head
      :can-add-comparison="false"
      :search-tools="searchTools"
      :settings="settings"
      :dimensions="dimensions"
      :metrics="metrics"
      :comparisons="comparisons"
      :sort="sort"
      @input="$emit('input', $event)"
      @sort="$emit('sort', $event)"
    />
    <tbody>
      <comparison-table-row
        v-for="record in formattedData"
        :key="'comparison-record-' + record.id"
        :record="record"
        :settings="settings"
        :dimensions="dimensions"
        :metrics="metrics"
        :comparisons="comparisons"
      />
    </tbody>
    <comparison-report-table-footer
      :data="data"
      :settings="settings"
      :dimensions="dimensions"
      :metrics="metrics"
      :comparisons="comparisons"
    />
  </table>
</template>

<script>
import ComparisonReportMixin from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportMixin';
import ComparisonTableRow from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonTableRow';
import ComparisonReportTableHead from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportTableHead';
import ComparisonReportTableFooter from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportTableFooter';

export default {
  components: {
    ComparisonReportTableFooter,
    ComparisonReportTableHead,
    ComparisonTableRow
  },
  mixins: [ComparisonReportMixin],
  props: {
    searchTools: {
      type: Array,
      default: null
    },
    shortCurrency: Boolean,
    data: {
      type: Array,
      default: null
    }
  }
};
</script>
