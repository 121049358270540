<template>
  <div class="report-action-bar flex justify-space-between">
    <slot />
    <action-button
      v-if="showDownload"
      tooltip="Download Individual PDFs"
      style-class="border-gray group hover:border-dark-silver w-22 h-14"
      :is-active="isDownloading"
      :disabled="disabled || disabledDownload"
      @click="showMassExportDialog = true"
    >
      <template #icon>
        <icon
          :icon="downloadIcon"
          class="icon-xl text-dark-silver group-hover:text-gunmetal"
        />
        <icon
          :icon="arrowIcon"
          class="icon-md text-dark-silver group-hover:text-gunmetal mx-2"
        />
        <icon
          :icon="usersIcon"
          class="icon-lg text-dark-silver group-hover:text-gunmetal"
        />
      </template>
    </action-button>
    <document-exporter
      v-if="showDownload"
      :url="documentUrl"
      :name="downloadName"
    >
      <template v-slot="{ exportToPdf }">
        <action-button
          class="pl-3"
          :is-active="isDownloading"
          tooltip="Download PDF"
          :disabled="disabled || disabledDownload"
          :icon="downloadIcon"
          @click="onDownload(exportToPdf)"
        />
      </template>
    </document-exporter>
    <action-button
      v-if="showCopy"
      class="pl-3"
      :is-active="isCopying"
      :disabled="disabled || disabledCopy"
      :tooltip="isCopying ? 'Copied!' : 'Copy Link'"
      :icon="copyIcon"
      @click="onCopy"
    />
    <action-button
      v-if="showPrint"
      class="pl-3"
      :is-active="isPrinting"
      :disabled="disabled || disabledPrint"
      tooltip="Print Document"
      :icon="printIcon"
      @click="onPrint"
    />
    <action-button
      v-if="showExport"
      class="pl-3"
      :disabled="disabled || disabledExport"
      :is-exporting="isExporting"
      tooltip="Export to CSV"
      :icon="exportIcon"
      @click="onExport"
    />
    <slot name="after" />

    <report-mass-export-dialog
      v-if="showMassExportDialog"
      :report-input="reportInput"
      @close="showMassExportDialog = false"
    />
  </div>
</template>

<script>
import DocumentExporter from '@/components/Core/DocumentExporter';
import ActionButton from '@/components/Core/Table/ActionButton';
import ReportMassExportDialog from '@/components/Supplier/Accounting/Reports/ReportMassExportDialog';
import { copyToClipboard, download } from '@/utils/helpers';
import {
  docs as copyIcon,
  filePdf as downloadIcon,
  longArrowRight as arrowIcon,
  printer as printIcon,
  shareSquareO as exportIcon,
  users as usersIcon
} from '@/vendor/icons';
import Papa from 'papaparse';

export default {
  components: {
    ReportMassExportDialog,
    DocumentExporter,
    ActionButton
  },
  props: {
    disabledCopy: Boolean,
    disabledDownload: Boolean,
    disabledExport: Boolean,
    disabledPrint: Boolean,
    showCopy: Boolean,
    showDownload: Boolean,
    showExport: Boolean,
    showPrint: Boolean,
    documentUrl: {
      type: String,
      required: true
    },
    downloadName: {
      type: String,
      default: 'Report_Download.pdf'
    },
    exportName: {
      type: String,
      default: 'Report_Export.csv'
    },
    reportInput: {
      type: Object,
      required: true
    },
    exportData: {
      type: Array,
      default: null
    },
    handlePrint: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    data: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      // State
      showMassExportDialog: false,
      isCopying: false,
      isDownloading: false,
      isExporting: false,
      isPrinting: false,

      // Icons
      arrowIcon,
      copyIcon,
      downloadIcon,
      exportIcon,
      printIcon,
      usersIcon
    };
  },
  methods: {
    onCopy() {
      this.$emit('copying', true);

      this.isCopying = true;
      copyToClipboard(process.env.VUE_APP_URL + this.documentUrl);

      setTimeout(() => {
        this.isCopying = false;
        this.$emit('copying', false);
      }, 3000);
    },
    onPrint() {
      this.$emit('print');

      if (this.handlePrint) {
        window.print();
      }
    },
    async onDownload(exportToPdf) {
      this.isDownloading = true;
      this.$emit('downloading', true);

      await exportToPdf();

      this.isDownloading = false;
      this.$emit('downloading', false);
    },
    onExport() {
      this.$emit('exporting', true);
      this.isExporting = true;
      const csv = Papa.unparse(this.exportData);
      download(csv, this.exportName);

      setTimeout(() => {
        this.isExporting = false;
        this.$emit('exporting', false);
      }, 500);
    }
  }
};
</script>

<style lang="scss">
@media print {
  .report-action-bar {
    display: none !important;
  }
}
</style>
