var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"doc-table w-full"},[_c('comparison-report-table-head',{attrs:{"indented":"","search-tools":_vm.searchTools,"settings":_vm.settings,"dimensions":_vm.dimensions,"metrics":_vm.metrics,"comparisons":_vm.comparisons,"sort":_vm.sort},on:{"input":function($event){return _vm.$emit('input', $event)},"sort":function($event){return _vm.$emit('sort', $event)}}}),_c('tbody',{staticClass:"dimension-columns-no-borders"},[_vm._l((_vm.groupedData),function(group){return [_c('tr',{key:'comparison-group-header-' + group.key,staticClass:"merged-start-row"},[(_vm.comparisons.length > 0)?[_c('td',{staticClass:"font-semibold",attrs:{"colspan":_vm.visibleDimensionColumns.length}},[_vm._v(" "+_vm._s(group.name)+" ")]),_vm._l((_vm.metrics.length * _vm.comparisons.length),function(i){return _c('td',{key:'td-placeholder-' + i})})]:[_c('td',{staticClass:"font-semibold",attrs:{"colspan":_vm.visibleDimensionColumns.length + _vm.metrics.length}},[_vm._v(" "+_vm._s(group.name)+" ")])]],2),_vm._l((group.records),function(record){return _c('comparison-table-row',{key:'comparison-record-' + record.id,attrs:{"indented":"","merged-row":"","record":record,"settings":_vm.settings,"dimensions":_vm.dimensions,"metrics":_vm.metrics,"comparisons":_vm.comparisons}})}),_c('tr',{key:'comparison-group-footer-' + group.key,staticClass:"details-record-footer"},[_c('td',{staticClass:"font-semibold merged-start-col",attrs:{"colspan":_vm.totalForColspan}},[_vm._v(" Total for "+_vm._s(group.name)+" ")]),(_vm.comparisons.length > 0)?[_vm._l((_vm.comparisons),function(comparison){return [_vm._l((_vm.metrics),function(metric){return [_c('td',{key:'group-' +
                    group.name +
                    '-comparison-' +
                    comparison.name +
                    '-total-' +
                    metric.name},[_vm._v(" "+_vm._s(_vm.formatField( group.totals[comparison.name][metric.name], metric.format ))+" ")])]})]})]:[_vm._l((_vm.metrics),function(metric,index){return [(index >= _vm.totalsSkipMetricCount)?_c('td',{key:'group-' + group.name + '-total-' + metric.name,class:{
                'merged-middle-col': index < _vm.metrics.length - 1,
                'merged-end-col': index === _vm.metrics.length - 1
              }},[_vm._v(" "+_vm._s(_vm.formatField(group.totals[metric.name], metric.format))+" ")]):_vm._e()]})]],2)]})],2),_c('comparison-report-table-footer',{attrs:{"data":_vm.data,"settings":_vm.settings,"dimensions":_vm.dimensions,"metrics":_vm.metrics,"comparisons":_vm.comparisons}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }