<script>
import {
  CountableReportFormats,
  formatField,
  ReportAggregate
} from '@/constants';

export default {
  props: {
    data: {
      type: Array,
      default: null
    },
    settings: {
      type: Object,
      required: true
    },
    dimensions: {
      type: Array,
      required: true
    },
    metrics: {
      type: Array,
      required: true
    },
    comparisons: {
      type: Array,
      required: true
    },
    filter: {
      type: Object,
      default: null
    },
    filterToolsState: {
      type: [Object, Array],
      default: null
    },
    sort: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    visibleDimensionColumns() {
      // We probably only want to show the Primary dimension columns, as the secondary columns are part of
      // the group label
      return this.primaryDimensionColumns.filter(d => !d.hidden);
    },
    visibleMetricColumns() {
      return this.metrics.filter(m => !m.exportOnly);
    },
    dimensionColumns() {
      let columns = [];

      for (let dimension of this.dimensions) {
        columns.push({ ...dimension.keyField, sortable: dimension.sortable });
        columns.push({ ...dimension.labelField, sortable: dimension.sortable });
      }

      return columns;
    },
    primaryDimensionColumns() {
      let columns = [];

      const dimension = this.primaryDimension;

      columns.push({ ...dimension.keyField, sortable: dimension.sortable });
      columns.push({ ...dimension.labelField, sortable: dimension.sortable });

      return columns;
    },
    primaryDimension() {
      return this.dimensions[0];
    },
    secondaryDimensions() {
      let dims = [...this.dimensions];
      dims.shift();
      return dims;
    },
    formattedData() {
      if (this.sort && this.sort.length > 0) {
        let sortedData = [...this.data];

        sortedData.sort((a, b) => {
          for (let sortBy of this.sort) {
            let valueA = a[sortBy.column];
            let valueB = b[sortBy.column];

            // Special case for handling Comparison Metrics
            if (sortBy.column.match(':')) {
              const [comparisonName, metricName] = sortBy.column.split(':');

              valueA = +a.comparisons[comparisonName][metricName];
              valueB = +b.comparisons[comparisonName][metricName];
            }

            if (!isNaN(valueA) && !isNaN(valueB)) {
              valueA = +valueA;
              valueB = +valueB;
            }

            if (valueA !== valueB) {
              if (sortBy.direction === 'desc') {
                return valueA > valueB ? -1 : 1;
              } else if (sortBy.direction === 'asc') {
                return valueA > valueB ? 1 : -1;
              }
            }
          }
        });

        return sortedData;
      } else {
        return this.data;
      }
    },
    groupedData() {
      let groups = {};

      for (let record of this.formattedData) {
        const groupKey = this.getGroupKey(record);

        if (!groups[groupKey]) {
          groups[groupKey] = {
            key: groupKey,
            name: this.getGroupName(record),
            records: []
          };
        }

        groups[groupKey].records.push(record);
      }

      groups = Object.values(groups);

      for (let group of groups) {
        group.totals = {};

        if (this.comparisons.length > 0) {
          for (let comparison of this.comparisons) {
            group.totals[comparison.name] = {};

            for (let metric of this.metrics) {
              let total = 0;

              for (let record of group.records) {
                total += +record.comparisons[comparison.name][metric.name];
              }

              group.totals[comparison.name][metric.name] = total;
            }
          }
        } else {
          for (let metric of this.metrics) {
            if (metric.aggregate !== ReportAggregate.NONE) {
              let total = 0;

              for (let record of group.records) {
                total += +record[metric.name];
              }

              group.totals[metric.name] = total;
            } else {
              group.totals[metric.name] = '';
            }
          }
        }
      }

      return groups.sort((a, b) => (a.name > b.name ? -1 : 1));
    },
    reportTotals() {
      let totals = {};

      for (let record of this.data) {
        if (this.comparisons.length > 0) {
          for (let comparisonName of Object.keys(record.comparisons)) {
            if (!totals[comparisonName]) {
              totals[comparisonName] = {};
            }

            let compareRecord = record.comparisons[comparisonName];

            for (let metricName of Object.keys(compareRecord)) {
              if (!totals[comparisonName][metricName]) {
                totals[comparisonName][metricName] = 0;
              }

              totals[comparisonName][metricName] += +compareRecord[metricName];
            }
          }
        } else {
          for (let metric of this.metrics) {
            if (CountableReportFormats.includes(metric.format)) {
              if (!totals[metric.name]) {
                totals[metric.name] = 0;
              }

              totals[metric.name] += +record[metric.name];
            }
          }
        }
      }

      return totals;
    },
    exportData() {
      let exportData = [];

      if (this.data) {
        for (let record of this.data) {
          let item = { ...record };

          delete item.id;
          delete item.comparisons;

          for (let comparison of this.comparisons) {
            for (let metric of this.metrics) {
              item[comparison.name + ': ' + metric.name] = record.comparisons[
                comparison.name
              ]
                ? record.comparisons[comparison.name][metric.name]
                : '';
            }
          }

          exportData.push(item);
        }
      }

      return exportData;
    }
  },
  methods: {
    formatField,
    getGroupKey(record) {
      let groupKey = '';

      for (let dimension of this.secondaryDimensions) {
        groupKey += (groupKey ? '-' : '') + record[dimension.keyField.value];
      }

      return groupKey;
    },
    getGroupName(record) {
      let groupName = '';

      for (let dimension of this.secondaryDimensions) {
        let value = record[dimension.labelField.value];

        if (dimension.format) {
          value = this.formatField(value, dimension.format);
        }

        groupName += (groupName ? ', ' : '') + value;
      }

      return groupName;
    }
  }
};
</script>
