<script>
import { massExportPdf } from '@/graphql/accounting/reports/exportReport.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    input: {
      type: Object,
      required: true
    }
  },
  query() {
    return {
      query: massExportPdf,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        input: this.input
      }
    };
  }
};
</script>
