<template>
  <report-document
    class="ar-aging-summary-report"
    v-bind="$props"
    :landscape="settings.isLandscape"
    :search-tools="undefined"
    :data="undefined"
    :settings="undefined"
    show-menu
    @hover="isHovering = $event"
    @update:landscape="$emit('update:landscape', $event)"
  >
    <div slot="subtitle">
      <ar-aging-report-subtitle
        :editable="isHovering"
        :supplier="supplier"
        :settings="settings"
        @settings="$emit('settings', $event)"
      />
    </div>
    <div slot="header-right">
      <report-filter-table
        :filterable="isHovering"
        :search-tools="searchTools"
        :tools-state="filterToolsState"
        @update:tools-state="$emit('update:filter-tools-state', $event)"
        @filter="$emit('filter', $event)"
      />
    </div>

    <div v-if="data" class="mt-8 w-full overflow-auto">
      <table class="doc-table w-full">
        <thead>
          <tr>
            <td>ID</td>
            <td>Customer</td>
            <td>Default Terms</td>
            <td>Current</td>
            <td>1-30</td>
            <td>31-60</td>
            <td>61-90</td>
            <td>91-120</td>
            <td>120+</td>
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in summaryData" :key="'report-record-' + record.id">
            <td>{{ record.id }}</td>
            <td>{{ record.customer }}</td>
            <td>{{ record.defaultTerms }}</td>
            <td>{{ record.Current | currency({ short }) }}</td>
            <td>{{ record['1-30'] | currency({ short }) }}</td>
            <td>{{ record['31-60'] | currency({ short }) }}</td>
            <td>{{ record['61-90'] | currency({ short }) }}</td>
            <td>{{ record['91-120'] | currency({ short }) }}</td>
            <td>{{ record['120+'] | currency({ short }) }}</td>
            <td>{{ record.total | currency({ short }) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="font-bold" colspan="3">Total</td>
            <td class="font-bold">
              {{ summaryTotals.Current | currency({ short }) }}
            </td>
            <td class="font-bold">
              {{ summaryTotals['1-30'] | currency({ short }) }}
            </td>
            <td class="font-bold">
              {{ summaryTotals['31-60'] | currency({ short }) }}
            </td>
            <td class="font-bold">
              {{ summaryTotals['61-90'] | currency({ short }) }}
            </td>
            <td class="font-bold">
              {{ summaryTotals['91-120'] | currency({ short }) }}
            </td>
            <td class="font-bold">
              {{ summaryTotals['120+'] | currency({ short }) }}
            </td>
            <td class="font-bold">
              {{ summaryTotals.total | currency({ short }) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </report-document>
</template>

<script>
import ArAgingReportMixin from '@/components/Supplier/Accounting/Reports/ArAgingReport/ArAgingReportMixin';
import ArAgingReportSubtitle from '@/components/Supplier/Accounting/Reports/ArAgingReport/ArAgingReportSubtitle';
import ReportDocument from '@/components/Supplier/Accounting/Reports/ReportDocument';
import ReportFilterTable from '@/components/Supplier/Accounting/Reports/ReportFilterTable';
import { date as dateFormat } from '@/utils/filters';

export default {
  components: {
    ArAgingReportSubtitle,
    ReportFilterTable,
    ReportDocument
  },
  mixins: [ArAgingReportMixin],
  props: {
    ...ReportDocument.props,
    title: {
      type: String,
      default: 'AR Aging Summary'
    },
    data: {
      type: Array,
      default: null
    },
    searchTools: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isHovering: false
    };
  },
  computed: {
    short() {
      return this.settings.isShortCurrency;
    },
    supplier() {
      return this.$store.getters['auth/supplier'];
    }
  },
  methods: {
    dateFormat
  }
};
</script>
