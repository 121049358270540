<template>
  <div class="table-head-column flex">
    <div class="label flex-grow">{{ label || column }}</div>
    <div v-if="sortable" class="sort">
      <a
        :class="{ 'text-gray': !currentDirection }"
        @click="$emit('sort', [{ column, direction: nextDirection }])"
      >
        <icon
          :icon="currentDirection === 'desc' ? sortDescIcon : sortAscIcon"
        />
      </a>
    </div>
  </div>
</template>

<script>
import {
  sortAmountAsc as sortAscIcon,
  sortAmountDesc as sortDescIcon
} from '@/vendor/icons';

export default {
  props: {
    column: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    sortable: Boolean,
    sort: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // Icons
      sortAscIcon,
      sortDescIcon
    };
  },
  computed: {
    currentDirection() {
      let sortColumn = this.sort.find(s => s.column === this.column);

      return sortColumn && sortColumn.direction;
    },
    nextDirection() {
      switch (this.currentDirection) {
        case 'asc':
          return 'desc';
        case 'desc':
          return '';
        default:
          return 'asc';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';
</style>
