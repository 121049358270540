<template>
  <collapsible-sidebar class="report-sidebar border-right">
    <el-form class="mt-5">
      <slot />

      <custom-el-form-item
        label="Layout"
        label-class="font-bold"
        class="text-base mt-6"
      >
        <el-switch
          :value="settings.isLandscape"
          active-text="Landscape"
          inactive-text="Portrait"
          @input="$emit('input', { ...settings, isLandscape: $event })"
        />
      </custom-el-form-item>
      <custom-el-form-item
        label="Number Format"
        label-class="font-bold"
        class="mt-6 text-base"
      >
        <el-switch
          v-model="settings.isShortCurrency"
          active-text="Rounded"
          inactive-text="Exact"
          @input="$emit('input', { ...settings, isShortCurrency: $event })"
        />
      </custom-el-form-item>

      <slot name="bottom" />
    </el-form>
  </collapsible-sidebar>
</template>

<script>
import CollapsibleSidebar from '@/components/Core/CollapsibleSidebar';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';

export default {
  components: {
    CollapsibleSidebar,
    CustomElFormItem
  },
  model: {
    prop: 'settings',
    event: 'input'
  },
  props: {
    settings: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss">
@media print {
  .report-sidebar {
    display: none !important;
  }
}
</style>
