<template>
  <div class="ar-report-subtitle">
    <div>{{ supplier.name }}</div>
    <div class="flex">
      <div class="label">Created:</div>
      <div class="value ml-2">{{ Date.now() | date }}</div>
    </div>
    <div
      :style="{ opacity: settings.asOfDate || editable ? 1 : 0 }"
      class="flex transition-all"
    >
      <div class="label">As Of:</div>
      <div class="value ml-2">
        <editable-document-item
          clearable
          style-class="block relative"
          :placeholder="dateFormat(Date.now())"
          datepicker
          :value="settings.asOfDate"
          :editable="editable"
          @change="$emit('settings', { ...settings, asOfDate: $event })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EditableDocumentItem from '@/components/Supplier/Billing/Document/EditableDocumentItem';

import { date as dateFormat } from '@/utils/filters';

export default {
  components: { EditableDocumentItem },
  props: {
    supplier: {
      type: Object,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    editable: Boolean
  },
  methods: {
    dateFormat
  }
};
</script>
