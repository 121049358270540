<template>
  <div class="comparison-form">
    <el-form>
      <custom-el-form-item label="Comparison Name" label-class="font-bold">
        <el-input v-model="comparisonInput.name" />
      </custom-el-form-item>
    </el-form>

    <search-tools-form
      v-if="searchTools"
      :tools="searchTools"
      class="mt-5"
      :tools-state.sync="comparisonInput.toolsState"
      @filter="comparisonInput.filter = $event"
    />

    <el-button
      v-if="!loading"
      class="button-blue w-full"
      :disabled="!comparisonInput.filter"
      @click="$emit('save', comparisonInput)"
    >
      {{ submitText }}
    </el-button>
    <loading-button v-else class="w-full" />
  </div>
</template>

<script>
import SearchToolsForm from '@/components/Core/SearchToolsForm';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';

const defaultComparisonInput = {
  name: '',
  filter: null,
  toolsState: null
};

export default {
  components: {
    CustomElFormItem,
    SearchToolsForm
  },
  props: {
    loading: Boolean,
    submitText: {
      type: String,
      default: 'Add Comparison'
    },
    input: {
      type: Object,
      default: () => {
        return {};
      }
    },
    searchTools: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      comparisonInput: { ...defaultComparisonInput, ...this.input }
    };
  },
  watch: {
    input() {
      this.comparisonInput = { ...defaultComparisonInput, ...this.input };
    }
  }
};
</script>
