<script>
import { getSalesSummaryReport } from '@/graphql/accounting/reports/getSalesSummaryReport.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    dimensions: {
      type: Array,
      required: true
    },
    metrics: {
      type: Array,
      required: true
    },
    comparisons: {
      type: Array,
      required: true
    },
    filter: {
      type: Object,
      default: null
    }
  },
  query() {
    return {
      query: getSalesSummaryReport,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        input: {
          dimensions: this.dimensions,
          metrics: this.metrics,
          comparisons: this.formattedComparisons,
          filter: this.filter
        }
      }
    };
  },
  computed: {
    formattedComparisons() {
      let comparisons = [];

      for (let comparison of this.comparisons) {
        comparisons.push({ ...comparison, toolsState: undefined });
      }

      return comparisons;
    }
  }
};
</script>
