export function cleanToolsState(filterToolsState) {
  let cleanState = null;
  if (filterToolsState?.filter) {
    cleanState = { filter: {} };
    for (let filterKey of Object.keys(filterToolsState.filter)) {
      const entry = filterToolsState.filter[filterKey];
      cleanState.filter[filterKey] = {
        displayValue: entry.displayValue,
        label: entry.label,
        searchValue: entry.searchValue,
        value: entry.value
      };
    }
  }

  return cleanState;
}
