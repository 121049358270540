<template>
  <div class="search-tools-form">
    <search-group
      v-for="toolGroup in tools"
      :key="'search-group-' + toolGroup.id"
      :value="toolsInput[toolGroup.name]"
      :group="toolGroup"
      @input="onSearch(toolGroup, $event)"
    />
  </div>
</template>
<script>
import SearchToolsMapperMixin from '@/components/Core/SearchToolsMapperMixin';
import SearchGroup from '@/components/Core/SearchGroup';
import { isEqual } from 'lodash';

export default {
  components: { SearchGroup },
  mixins: [SearchToolsMapperMixin],
  props: {
    // The list of Search Tools and their respective options
    tools: {
      type: Array,
      required: true
    },
    // The State of the search tools based on the user input
    toolsState: {
      type: [Object, Array],
      default: null
    },
    // The resolved search value based on the Search Tools current state
    // (ie: this is the search value passed as the sort / filter arguments to the GraphQL endpoint)
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      toolsInput: this.toolsState || {},
      searchValue: {}
    };
  },
  watch: {
    toolsState() {
      this.toolsInput = this.toolsState || {};
    }
  },
  methods: {
    onSearch(toolGroup, input) {
      this.toolsInput = { ...this.toolsInput, [toolGroup.name]: input };

      // The search tools' state
      this.$emit('update:tools-state', this.toolsInput);

      const newSearchValue = this.mapSearchGroups(this.toolsInput);
      // The search tools searchable value

      // As an optimization, sometimes the input can trigger a change w/o generating
      // a unique search value, so we can ignore
      if (!isEqual(this.searchValue, newSearchValue)) {
        this.searchValue = newSearchValue;

        this.$emit('input', this.searchValue);
        this.$emit('filter', this.searchValue.filter);
        this.$emit('sort', this.searchValue.sort);
      }
    }
  }
};
</script>
