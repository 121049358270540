<template>
  <div class="supplier-sales-summary-report flex">
    <supplier-report-sidebar
      v-model="reportInput.settings"
      class="flex-shrink-0"
    />

    <div class="flex-grow ml-5 report-document-box">
      <supplier-sales-summary-report-search-tools-query
        @data="
          searchTools =
            $event.supplier.accountingReports.salesSummaryReport.searchTools
        "
      />

      <supplier-sales-summary-report-query
        v-if="reportInputValid"
        :loading.sync="isLoadingReport"
        :dimensions="reportInput.dimensions"
        :metrics="reportInput.metrics"
        :comparisons="reportInput.comparisons"
        :filter="reportInput.filter"
        @data="
          reportData =
            $event.supplier.accountingReports.salesSummaryReport.comparison
        "
      />

      <div
        class="transition-all transition-d-.5"
        :style="{
          maxWidth: reportInput.settings.isLandscape ? '11in' : '8.5in'
        }"
      >
        <transition name="fade">
          <comparison-report-action-bar
            name="SalesSummary"
            url="/v3/supplier/accounting/reports/sales-summary"
            :disabled="isLoadingReport || !reportData"
            :data="reportData"
            report-id="sales-summary"
            :comparisons="reportInput.comparisons"
            :dimensions="reportInput.dimensions"
            :filter="reportInput.filter"
            :metrics="reportInput.metrics"
            :settings="reportInput.settings"
            :sort="reportInput.sort"
            :filter-tools-state="reportInput.filterToolsState"
            class="my-5"
            @downloading="isDownloading = $event"
          />
        </transition>

        <comparison-report-document
          shadow
          title="Sales Summary"
          :available-dimensions="availableDimensions"
          :comparisons="reportInput.comparisons"
          :dimensions="reportInput.dimensions"
          :filter="reportInput.filter"
          :metrics="reportInput.metrics"
          :settings.sync="reportInput.settings"
          :sort="reportInput.sort"
          :loading-body="isLoadingReport"
          :loading="!searchTools"
          :data="reportData"
          :search-tools="searchTools"
          :filter-tools-state.sync="reportInput.filterToolsState"
          :exporting="isDownloading"
          @sort="reportInput.sort = $event"
          @settings="reportInput.settings = $event"
          @input="reportInput = { ...reportInput, ...$event }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ComparisonReportActionBar from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportActionBar';
import ComparisonReportDocument from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportDocument';
import SupplierReportSidebar from '@/components/Supplier/Accounting/Reports/SupplierReportSidebar';
import SupplierSalesSummaryReportQuery from '@/components/Queries/Accounting/Reports/SupplierSalesSummaryReportQuery';
import SupplierSalesSummaryReportSearchToolsQuery from '@/components/Queries/Accounting/Reports/SupplierSalesSummaryReportSearchToolsQuery';

import { date } from '@/utils/filters';
import { FeatureFlags, ReportAggregate, ReportFormat } from '@/constants';
import moment from 'moment';
import { UrlParamFormat, urlParams } from '@/utils/helpers';

export default {
  components: {
    ComparisonReportActionBar,
    ComparisonReportDocument,
    SupplierReportSidebar,
    SupplierSalesSummaryReportQuery,
    SupplierSalesSummaryReportSearchToolsQuery
  },
  data() {
    const reportParams = urlParams('report', UrlParamFormat.BASE64_JSON) || {};

    let thisYear = {
      start: moment().startOf('year'),
      end: moment().endOf('year')
    };

    let lastYear = {
      start: moment()
        .add(-1, 'year')
        .startOf('year'),
      end: moment()
        .add(-1, 'year')
        .endOf('year')
    };

    return {
      isLoadingReport: false,
      isDownloading: false,
      reportData: null,
      searchTools: null,
      reportInput: {
        settings: {
          isShortCurrency: false,
          isLandscape: true
        },
        dimensions: [],
        metrics: [
          {
            name: 'Total Ads',
            field: 'id',
            aggregate: ReportAggregate.COUNT,
            format: ReportFormat.INTEGER,
            sortable: true
          },
          {
            name: 'Total Amount',
            field: 'orderLineItem.pay_supplier',
            aggregate: ReportAggregate.SUM,
            format: ReportFormat.CURRENCY,
            sortable: true
          }
        ],
        comparisons: [
          {
            name:
              'Last Year (' +
              date(lastYear.start) +
              ' to ' +
              date(lastYear.end) +
              ')',
            filter: {
              start_date: {
                '>=': lastYear.start,
                '<=': lastYear.end
              }
            },
            toolsState: {
              filter: {
                'Run Date': {
                  value: {
                    start_date: { '<=': lastYear.end },
                    end_date: { '>=': lastYear.start }
                  }
                }
              }
            }
          },
          {
            name:
              'This Year (' +
              date(thisYear.start) +
              ' to ' +
              date(thisYear.end) +
              ')',
            filter: {
              start_date: {
                '>=': thisYear.start,
                '<=': thisYear.end
              }
            },
            toolsState: {
              filter: {
                'Run Date': {
                  value: {
                    start_date: { '<=': thisYear.end },
                    end_date: { '>=': thisYear.start }
                  }
                }
              }
            }
          }
        ],
        sort: [],
        filter: {},
        filterToolsState: null,
        ...reportParams
      }
    };
  },
  computed: {
    reportInputValid() {
      return (
        this.reportInput.dimensions.length > 0 &&
        this.reportInput.metrics.length > 0 &&
        this.reportInput.comparisons.length > 0
      );
    },
    availableDimensions() {
      const reps = [];

      if (this.$feature(FeatureFlags.REPORTING_REPS_DIMENSION)) {
        reps.push({
          labelField: {
            key: 'customer.reps.email',
            value: 'Rep Email'
          },
          keyField: {
            key: 'customer.reps.id',
            value: 'Rep ID',
            hidden: true
          },
          allowMultipleCardinality: true,
          sortable: true
        });
      }

      return [
        {
          labelField: {
            key: 'buyer.buyer_company',
            value: 'Customer'
          },
          keyField: {
            key: 'buyer.id',
            value: 'ID'
          },
          sortable: true
        },
        ...reps,
        {
          labelField: {
            key: 'customer.type',
            value: 'Customer Type'
          },
          keyField: {
            key: 'customer.type',
            value: 'Type',
            hidden: true
          },
          sortable: true
        },
        {
          labelField: {
            key: 'orderProduct.medium_name',
            value: 'Medium'
          },
          keyField: {
            key: 'orderProduct.medium_name',
            value: 'Medium ID',
            hidden: true
          },
          sortable: true
        },
        {
          labelField: {
            key: 'orderProduct.property_name',
            value: 'Property'
          },
          keyField: {
            key: 'orderProduct.property_name',
            value: 'Property ID',
            hidden: true
          },
          sortable: true
        },
        {
          labelField: {
            key: 'orderProduct.collection_name',
            value: 'Collection'
          },
          keyField: {
            key: 'orderProduct.collection_name',
            value: 'Collection ID',
            hidden: true
          },
          sortable: true
        },
        {
          labelField: {
            key: 'orderProduct.product_name',
            value: 'Product'
          },
          keyField: {
            key: 'orderProduct.product_name',
            value: 'Product ID',
            hidden: true
          },
          sortable: true
        },
        {
          labelField: {
            key: 'orderProduct.product_variant_name',
            value: 'Variant'
          },
          keyField: {
            key: 'orderProduct.product_variant_id',
            value: 'Variant ID',
            hidden: true
          },
          sortable: true
        }
      ];
    }
  },
  mounted() {
    if (this.reportInput.dimensions.length === 0) {
      this.reportInput.dimensions.push(this.availableDimensions[0]);
    }
  }
};
</script>
<style lang="scss">
@media print {
  .report-document-box {
    margin: 0 !important;
  }
}
</style>
