<template>
  <div class="comparison-report-subtitle">
    <div>{{ supplier.name }}</div>
    <div class="flex">
      <div class="label">Created:</div>
      <div class="value ml-2">{{ Date.now() | date }}</div>
    </div>
    <template v-if="availableDimensions.length > 0">
      <dimension-selection-form
        v-if="canChangePrimary"
        :dimensions="dimensions"
        primary
        :editable="editable"
        :available-dimensions="availableDimensions"
        @input="$emit('input', $event)"
      />
      <div class="relative min-h-1.5">
        <transition name="fadeDown">
          <dimension-selection-form
            v-if="dimensions.length > 0 && (editable || dimensions.length > 1)"
            :dimensions="dimensions"
            :editable="editable"
            :available-dimensions="availableDimensions"
            @input="$emit('input', $event)"
          />
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import DimensionSelectionForm from '@/components/Supplier/Accounting/Reports/ComparisonReport/DimensionSelectionForm';

export default {
  components: { DimensionSelectionForm },
  props: {
    canChangePrimary: {
      type: Boolean,
      default: true
    },
    supplier: {
      type: Object,
      required: true
    },
    availableDimensions: {
      type: Array,
      required: true
    },
    dimensions: {
      type: Array,
      default: null
    },
    editable: Boolean
  }
};
</script>
