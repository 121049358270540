<template>
  <tfoot class="comparison-report-table-footer font-bold">
    <tr>
      <td :colspan="totalColSpan">Total ({{ data.length }} records)</td>
      <template v-if="comparisons.length > 0">
        <template v-for="comparison in comparisons">
          <td
            v-for="metric in metrics"
            :key="'comparison-total-' + comparison.name + '-' + metric.name"
          >
            {{
              formatField(
                reportTotals[comparison.name] &&
                  reportTotals[comparison.name][metric.name],
                metric.format
              )
            }}
          </td>
        </template>
      </template>
      <template v-else>
        <td
          v-for="metric in totalsMetrics"
          :key="'comparison-total-' + metric.name"
        >
          {{ formatField(reportTotals[metric.name], metric.format) }}
        </td>
      </template>
    </tr>
  </tfoot>
</template>

<script>
import ComparisonReportMixin from '@/components/Supplier/Accounting/Reports/ComparisonReport/ComparisonReportMixin';

import { CountableReportFormats } from '@/constants';

export default {
  mixins: [ComparisonReportMixin],
  computed: {
    totalsMetrics() {
      if (this.totalColSpan === 2) {
        let metrics = [...this.metrics].filter(m => !m.exportOnly);
        metrics.shift();
        return metrics;
      } else {
        return this.metrics;
      }
    },
    totalColSpan() {
      let dimCols = this.visibleDimensionColumns.length;

      if (this.comparisons.length > 0 || dimCols > 1) {
        return dimCols;
      } else {
        // Try to expand our Totals column if the next column is going to be blank anyway
        return CountableReportFormats.includes(this.metrics[0].format) ? 1 : 2;
      }
    }
  }
};
</script>
