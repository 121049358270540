<template>
  <div class="page-supplier-accounting-reports page-content">
    <div class="page-header">
      <the-page-header>
        <template slot="title">
          Reporting
        </template>
        <template slot="subtitle">
          Generate custom billing reports below. Hover over report documents to
          filter and adjust settings.
        </template>
      </the-page-header>

      <el-tabs
        :value="$route.name"
        @tab-click="$router.push({ name: $event.name })"
      >
        <el-tab-pane
          key="tab-ar-aging-report"
          name="supplier.accounting.ar-aging-report"
          label="Accounts Receivable Aging"
        />
        <el-tab-pane
          key="tab-sales-summary"
          name="supplier.accounting.sales-summary-report"
          label="Sales Summary"
        />
        <el-tab-pane
          key="tab-transaction-report"
          name="supplier.accounting.transaction-report"
          label="Transaction Report"
        />
        <el-tab-pane
          v-if="$feature('cc-transaction-report')"
          key="tab-cc-transaction-report"
          name="supplier.accounting.cc-transaction-report"
          label="Credit Card Transaction Report"
        />
      </el-tabs>
    </div>

    <transition name="fade" mode="out-in">
      <div class="page-body">
        <router-view />
      </div>
    </transition>
  </div>
</template>

<script>
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: { ThePageHeader }
};
</script>

<style lang="scss">
@media print {
  .page-header {
    display: none;
  }

  .page-content {
    padding: 0;
  }

  .page-body {
    margin: 0;
  }
}
</style>
