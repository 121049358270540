var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"comparison-table-row",class:{ 'merged-end-row': _vm.mergedRow }},[_vm._l((_vm.visibleDimensionColumns),function(column,index){return _c('td',{key:'dimension-column-' + column.key,staticClass:"dimension-column",class:{
      'merged-start-col': _vm.mergedRow && index === 0,
      'merged-middle-col': _vm.mergedRow && index > 0,
      indented: _vm.indented && index === 0
    }},[_vm._v(" "+_vm._s(_vm.record[column.value])+" ")])}),(_vm.comparisons.length > 0)?[_vm._l((_vm.comparisons),function(comparison){return _vm._l((_vm.metrics),function(metric){return _c('td',{key:'metric-column-' + comparison.name + '-' + metric.name,staticClass:"metric-column"},[(_vm.record.comparisons[comparison.name])?[_vm._v(" "+_vm._s(_vm.formatField( _vm.record.comparisons[comparison.name][metric.name], metric.format ))+" ")]:_vm._e()],2)})})]:_vm._l((_vm.visibleMetricColumns),function(metric,index){return _c('td',{key:'metric-column-' + metric.name,staticClass:"metric-column",class:{
        'merged-middle-col': _vm.mergedRow && index < _vm.metrics.length - 1,
        'merged-end-col': _vm.mergedRow && index === _vm.metrics.length - 1,
        indented: _vm.indented && index === 0
      }},[_c('div',[_vm._v(" "+_vm._s(_vm.formatField(_vm.record[metric.name], metric.format))+" ")])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }